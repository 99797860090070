import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { finalize } from 'rxjs';
import { User } from 'src/app/core/models/auth.models';
import { SystemConfiguration, SystemConfigurationType } from 'src/app/core/models/system-configuration.models';
import { AuthService } from 'src/app/core/service/auth.service';
import { UsersService } from 'src/app/core/service/users.service';
import { isArray } from 'underscore';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SystemConfigurationService } from 'src/app/core/service/system-configuration.service';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.less'],
})
export class PrivacyModalComponent implements OnInit {
  @ViewChild('privacyPolicyElem') privacyPolicyElem: ElementRef | null = null;
  @ViewChild('content') content: ElementRef | null = null;

  currentUser: User | null = null;
  systemConfig: SystemConfiguration | null = null;

  showModal: boolean = false;
  loading: boolean = false;
  saving: boolean = false;
  acceptedPrivacy: boolean = false;
  disableSave: boolean = true;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected authSrv: AuthService,
    protected userSrv: UsersService,
    private notification: NzNotificationService,
    protected systemConfigSrv: SystemConfigurationService,
    protected nzModalSrv: NzModalService,
    protected router: Router
  ) {
    this.currentUser = this.authSrv.currentUser;
  }

  ngOnInit() {
    if (!this.currentUser?.privacyPolicyAccepted) {
      this.getSystemConfigInfo();
    }
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 500) {
      this.disableSave = false;
    }
  }

  getSystemConfigInfo() {
    this.loading = true;
    this.systemConfigSrv
      .getByType(SystemConfigurationType.PRIVACY)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (res: any) => {
          this.systemConfig =
            res?.data && isArray(res.data) && res.data.length > 0 ? new SystemConfiguration(res.data[0]) : null;

          setTimeout(() => {
            if (this.privacyPolicyElem?.nativeElement.offsetHeight < this.content?.nativeElement.offsetHeight) {
              this.disableSave = false;
            }
          }, 500);

          this.showModal = true;
        },
        (error: any) => {
          console.warn(`PrivacyModalComponent ~ getSystemConfigInfo:`, error);
        }
      );
  }

  save() {
    this.saving = true;
    this.userSrv
      .acceptPrivacy()
      .pipe(finalize(() => (this.saving = false)))
      .subscribe(
        (data: any) => {
          this.notification.create('success', 'Sucesso', 'Política de privacidade aceite!');
          this.showModal = false;
        },
        (error: any) => {
          console.warn(`PrivacyModalComponent ~ save:`, error);
          this.notification.create('error', 'Erro', error);
        }
      );
  }

  logout() {
    this.authSrv.logout().subscribe(() => {
      this.router.navigate(['/auth/login']);
    });
  }
}
