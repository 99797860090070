import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { User } from '../models/auth.models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Company } from '../models/company.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  // TOKEN REFRESH VARS
  isRefreshing = false;
  $isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // END TOKEN REFRESH VARS

  private user: User | null = null;
  private company: Company | null = null;

  constructor(private http: HttpClient) {}

  /**
   * Returns the current user
   */
  get currentUser(): User | null {
    return this.user;
  }

  /**
   * Returns the company selected
   */
  get companySelected(): Company | null {
    return this.company;
  }

  selectCompany(v: Company | null) {
    this.company = v;
  }

  /**
   * Performs the login auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string): any {
    return this.http
      .post<any>(
        `${environment.API_URL}/api/auth/login`,
        {
          email,
          password,
        },
        {
          headers: new HttpHeaders().append('no-token', 'true'),
          withCredentials: true,
        }
      )
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user.data[0] && user.data[0].accessToken) {
            // store user details and jwt in session
            user.data[0].payload.token = user.data[0].accessToken;
            user.data[0].payload.expiresIn = new Date(Date.now() + user.data[0].expiresIn);

            this.user = new User(user.data[0].payload);
            this.setRefreshingToken(false);
          }

          return user;
        })
      );
  }

  /**
   * Performs the signup auth
   * @param name name of user
   * @param email email of user
   * @param password password of user
   */
  signup(data: any): any {
    return this.http.post<any>(`${environment.API_URL}/api/company`, data, { headers: this.headers });
  }

  logoutAPI() {
    return this.http.post(
      `${environment.API_URL}/api/auth/logout`,
      {},
      {
        headers: new HttpHeaders().append('no-token', 'true'),
        withCredentials: true,
      }
    );
  }

  /**
   * Logout the user
   */
  logout() {
    return this.http
      .post<any>(
        `${environment.API_URL}/api/auth/logout`,
        {},
        {
          headers: new HttpHeaders().append('no-token', 'true'),
          withCredentials: true,
        }
      )
      .pipe(
        first(),
        tap((resp: any) => {
          this.user = null;
        })
      );
  }

  initialRefreshToken(): Observable<any> {
    return this.refreshToken().pipe(catchError(() => of(true)));
  }

  /**
   * Refresh Token
   * Gets a new access token from the refreshtoken present in the cookies
   */
  refreshToken(): Observable<any> {
    return new Observable((observer) => {
      this.setRefreshingToken(true);

      return this.http
        .post<any>(
          `${environment.API_URL}/api/auth/refreshToken`,
          {},
          {
            headers: {
              'no-error': 'true',
              'no-token': 'true',
            },
            withCredentials: true,
          }
        )
        .pipe(finalize(() => this.setRefreshingToken(false)))
        .subscribe(
          (result) => {
            if (result && result.data && result.data.length > 0) {
              this.user = new User(result.data[0].payload);
              this.user.token = result.data[0].accessToken;
              this.user.expiresIn = new Date(Date.now() + result.data[0].expiresIn);

              observer.next(result);
              observer.complete();
            }
          },
          (error: any) => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  private setRefreshingToken(isRefreshing: boolean) {
    this.isRefreshing = isRefreshing;
    this.$isRefreshing.next(this.isRefreshing);
  }

  hasRole(role: string): boolean {
    if (this.user) {
      return this.user.role.includes(role);
    } else {
      return false;
    }
  }

  recoverPassword(data: any) {
    return this.http.post(`${environment.API_URL}/api/auth/passwordReset`, data, {
      headers: new HttpHeaders().append('no-token', 'true'),
      withCredentials: true,
    });
  }

  recoverAccount(data: any) {
    return this.http.post(`${environment.API_URL}/api/auth/passwordRecovery`, data, {
      headers: new HttpHeaders().append('no-token', 'true'),
      withCredentials: true,
    });
  }

  verificationAccount(data: any) {
    return this.http.post(`${environment.API_URL}/api/auth/verificationAdmin`, data, {
      headers: new HttpHeaders().append('no-token', 'true'),
      withCredentials: true,
    });
  }
}
