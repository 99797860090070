<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="200px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <img
        [src]="isCollapsed ? '../../assets/images/antram-sm.png' : '../../assets/images/logo-antram-light.png'"
        alt="logo"
      />
    </div>

    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <div *ngFor="let item of menu">
        <li
          nz-menu-item
          nz-tooltip
          nzTooltipPlacement="right"
          [nzTooltipTitle]="isCollapsed ? item.title : ''"
          [nzDisabled]="item.disabled"
          [nzMatchRouter]="true"
          [routerLink]="item.link"
          *ngIf="!item?.hidden && !item.children"
        >
          <span nz-icon [nzType]="item.icon"></span>
          <span *ngIf="!isCollapsed">{{ item.title }}</span>
        </li>
        <li
          nz-submenu
          [nzTitle]="!isCollapsed ? item.title : ''"
          [nzDisabled]="item.disabled"
          [nzIcon]="item.icon"
          *ngIf="!item.hidden && item.children"
        >
          <ul>
            <li
              nz-menu-item
              nz-tooltip
              nzTooltipPlacement="right"
              *ngFor="let child of item.children"
              [nzTooltipTitle]="isCollapsed ? child.title : ''"
              [nzDisabled]="child.disabled"
              [nzMatchRouter]="true"
              [routerLink]="child.link"
            >
              <span nz-icon [nzType]="child.icon"></span>
              <span>{{ child.title }}</span>
            </li>
          </ul>
        </li>
      </div>
      <!-- <li
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="currentUser?.isAntram ? 'Antram' : currentUser?.isAdmin ? currentUser?.company?.name : 'XpertGo'"
        class="company"
      >
        <span nz-icon nzType="build"></span>
        <span class="info">
          <small *ngIf="currentUser?.isAdmin">Licenciado a</small>
          <div>{{ currentUser?.isAntram ? 'Antram' : currentUser?.isAdmin ? currentUser?.company?.name : 'XpertGo' }}</div>
          <small>V. {{ version }}</small>
        </span>
      </li> -->
    </ul>

    <!-- <div class="company">
      <span nz-icon nzType="build"></span>
      <span class="info">
        <small *ngIf="currentUser?.isAdmin">Licenciado a</small>
        <div>{{ currentUser?.isAntram ? 'Antram' : currentUser?.isAdmin ? currentUser?.company?.name : 'XpertGo' }}</div>
        <small>V. 0.0.0</small>
      </span>
    </div> -->
  </nz-sider>
  <nz-layout style="height: 100%; overflow: auto">
    <nz-header>
      <!-- [ngClass]="currentUser?.loginType == LoginType.DEMO ? 'demoClass' : (currentUser?.loginType == LoginType.DEBT ? 'debtClass' : (currentUser?.loginType == LoginType.VIEW ? 'viewClass': '') )" -->
      <div
        class="app-header"
        [ngClass]="
          currentUser?.loginType == LoginType.DEMO
            ? 'demoClass'
            : currentUser?.loginType == LoginType.DEBT
            ? 'debtClass'
            : currentUser?.loginType == LoginType.VIEW
            ? 'viewClass'
            : ''
        "
      >
        <div>
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
          </span>
          <span *ngIf="currentUser?.loginType == LoginType.NONE" class="company-name">{{
            currentUser?.isAntram ? 'Antram' : currentUser?.isAdmin ? currentUser?.company?.name : 'XpertGo'
          }}</span>
        </div>
        <span *ngIf="currentUser?.loginType == LoginType.DEMO" class="demoText">Versão de Demonstração</span>
        <span *ngIf="currentUser?.loginType == LoginType.VIEW" class="viewText"> Modo Consulta</span>
        <span
          *ngIf="currentUser?.loginType == LoginType.DEBT"
          class="debtText"
          [title]="'Existem faturas por liquidar'"
        >
          <span nz-icon nzType="exclamation-circle" nzTheme="outline"></span> Existem faturas por liquidar</span
        >
        <div style="height: 64px">
          <!-- <span class="header-trigger">
            <nz-badge [nzCount]="5">
              <span></span>
            </nz-badge>
          </span> -->
          <span class="header-trigger" (click)="profile()">
            <nz-avatar [nzText]="currentUser | xUser : 'initials'" [nzSrc]="currentUser?.photo || ''"></nz-avatar>
            <span class="username">{{ currentUser | xUser : 'username' }}</span>
          </span>
          <span class="header-trigger" (click)="logout()">
            <span class="trigger" nz-icon nzType="logout" nzTheme="outline"></span>
          </span>
        </div>
      </div>
    </nz-header>
    <nz-content class="content">
      <router-outlet></router-outlet>

      <!-- Privacy Modal -->
      <app-privacy-modal></app-privacy-modal>
    </nz-content>
    <nz-footer>
      <div class="footer">
        {{ yearInfo }} | Desenvolvido por <a href="https://www.xpertgo.pt/" target="_blank">XpertGo</a> | V.
        {{ version }}
      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>
