import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { pt_PT } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { LayoutContainerComponent } from './layout-container/layout-container.component';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { AuthService } from './core/service/auth.service';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { PipesModule } from './core/pipes/pipes.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PrivacyModalModule } from './pages/home/privacy-modal/privacy-modal.module';
import { first, Observable } from 'rxjs';

registerLocaleData(pt);

function initializeApp(authSrv: AuthService): () => Observable<any> {
  return () => {
    return authSrv.initialRefreshToken().pipe(first());
  };
}

@NgModule({
  declarations: [AppComponent, LayoutContainerComponent],
  imports: [
    BrowserModule,
    PipesModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PrivacyModalModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzNotificationModule,
    NzAvatarModule,
    NzToolTipModule,
    AppRoutingModule,
  ],
  providers: [
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AuthService],
    },
    NzMessageService,
    { provide: NZ_I18N, useValue: pt_PT },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
