import { User } from './auth.models';
import { Configuration } from './configuration.models';
import { Country } from './country.models';

export enum CompanyStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ANALYSIS = 'ANALYSIS',
  DELETED = 'DELETED',
  CONSULT = 'CONSULT',
}
export enum AntramSIStatus {
  CHECK = 'CHECK',
  NOTCHECK = 'NOTCHECK',
  LOADING = 'LOADING',
}

export enum AlertRenovationEnum {
  SHOW = 'SHOW',
  NOTSHOW = 'NOTSHOW',
  LOADING = 'LOADING',
}

export enum EntityTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  COLLECTIVE = 'COLLECTIVE',
}

export enum GuideEntityType {
  EXPEDITOR = 'EXPEDITOR',
  TRANSPORTER = 'TRANSPORTER',
}

export class Company {
  id: string;
  nif: string | null;
  name: string | null;
  email: string | null;
  contact: string | null;
  address: string | null;
  establishment: string | null;
  stablishment: string | null;
  locality: string | null;
  postalCode: string | null;
  status: CompanyStatus;
  licensesToRequest: number | null;
  entityType: EntityTypes;
  countryId: number;
  establishmentId: number;
  isDemo: boolean;
  isPassiveSubject: boolean;
  isProtocol: boolean;
  isVatIsention: boolean;
  ignoreAnualSubscription: boolean;
  isTransporter: boolean;
  isShipper: boolean;
  licenseNumber: string | null;

  rittSubscribed: boolean;
  eGuidesSubscribed: boolean;

  createdById: string | null;
  updatedById: string | null;
  approvedById: string | null;
  partnerId: string | null;
  costCenterId: string | null;
  renewalDate: Date | null;

  createdBy: User | null;
  updatedBy: User | null;
  approvedBy: User | null;
  analysesSetBy: User | null;
  partner: any | null;
  costCenter: Configuration | null;
  country: Country | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.nif = $obj && $obj.nif ? $obj.nif : null;
    this.name = $obj && $obj.name ? $obj.name : null;
    this.email = $obj && $obj.email ? $obj.email : null;
    this.contact = $obj && $obj.contact ? $obj.contact : null;
    this.address = $obj && $obj.address ? $obj.address : null;
    this.establishment = $obj && $obj.establishment ? $obj.establishment : null;
    this.stablishment = $obj && $obj.stablishment ? $obj.stablishment : null;
    this.locality = $obj && $obj.locality ? $obj.locality : null;
    this.postalCode = $obj && $obj.postalCode ? $obj.postalCode : null;
    this.status = $obj && $obj.status ? $obj.status : CompanyStatus.PENDING;
    this.licensesToRequest = $obj && $obj.licensesToRequest ? $obj.licensesToRequest : null;
    this.entityType = $obj && $obj.entityType ? $obj.entityType : null;
    this.countryId = $obj && $obj.countryId ? $obj.countryId : null;
    this.establishmentId = $obj && $obj.establishmentId ? $obj.establishmentId : null;
    this.isDemo = $obj && $obj.isDemo ? $obj.isDemo : false;
    this.isTransporter = $obj && $obj.isTransporter ? $obj.isTransporter : false;
    this.isShipper = $obj && $obj.isShipper ? $obj.isShipper : false;
    this.licenseNumber = $obj && $obj.licenseNumber ? $obj.licenseNumber : false;
    this.isVatIsention = $obj && $obj.isVatIsention ? $obj.isVatIsention : false;
    this.isProtocol = $obj && $obj.isProtocol ? $obj.isProtocol : false;
    this.isPassiveSubject = $obj && $obj.isPassiveSubject ? $obj.isPassiveSubject : false;
    this.ignoreAnualSubscription = $obj && $obj.ignoreAnualSubscription ? $obj.ignoreAnualSubscription : false;

    this.rittSubscribed = $obj?.rittSubscribed ?? false;
    this.eGuidesSubscribed = $obj?.eGuidesSubscribed ?? false;

    this.createdById = $obj && $obj.createdById ? $obj.createdById : $obj && $obj.createdBy ? $obj.createdBy.id : null;
    this.updatedById = $obj && $obj.updatedById ? $obj.updatedById : $obj && $obj.updatedBy ? $obj.updatedBy.id : null;
    this.approvedById =
      $obj && $obj.approvedById ? $obj.approvedById : $obj && $obj.approvedBy ? $obj.approvedBy.id : null;
    this.partnerId = $obj && $obj.partnerId ? $obj.partnerId : $obj && $obj.partner ? $obj.partner.id : null;
    this.costCenterId =
      $obj && $obj.costCenterId ? $obj.costCenterId : $obj && $obj.costCenter ? $obj.costCenter.id : null;
    this.analysesSetBy = $obj && $obj.analysesSetBy ? $obj.analysesSetBy : null;
    this.renewalDate = $obj && $obj.renewalDate ? $obj.renewalDate : null;

    this.createdBy = $obj && $obj.createdBy ? new User($obj.createdBy) : null;
    this.updatedBy = $obj && $obj.updatedBy ? new User($obj.updatedBy) : null;
    this.approvedBy = $obj && $obj.approvedBy ? new User($obj.approvedBy) : null;
    this.partner = $obj && $obj.partner ? new User($obj.partner) : null;
    this.costCenter = $obj && $obj.costCenter ? new Configuration($obj.costCenter) : null;
    this.country = $obj && $obj.country ? new Country($obj.country) : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get isPending() {
    return this.status === CompanyStatus.PENDING;
  }
  get isAnalysis() {
    return this.status === CompanyStatus.ANALYSIS;
  }
  get isApproved() {
    return this.status === CompanyStatus.APPROVED;
  }

  get hasRitt() {
    return this.rittSubscribed;
  }
  get hasGuias() {
    return this.eGuidesSubscribed;
  }

  get addressComplete() {
    const addresses = [];

    if (this.address) {
      addresses.push(this.address);
    }
    if (this.postalCode) {
      addresses.push(this.postalCode);
    }
    if (this.establishment) {
      addresses.push(this.establishment);
    }

    return addresses.toString().replace(/,/g, ', ');
  }

  get statusDescription() {
    switch (this.status) {
      case CompanyStatus.PENDING: {
        return 'Pendente';
      }
      case CompanyStatus.ANALYSIS: {
        return 'Em Análise';
      }
      case CompanyStatus.APPROVED: {
        return 'Aprovado';
      }
      case CompanyStatus.CONSULT: {
        return 'Consulta';
      }
      case CompanyStatus.DELETED: {
        return 'Inativo';
      }
      default: {
        return 'Pendente';
      }
    }
  }

  get statusColor() {
    switch (this.status) {
      case CompanyStatus.PENDING: {
        return 'orange';
      }
      case CompanyStatus.CONSULT: {
        return 'blue';
      }
      case CompanyStatus.ANALYSIS: {
        return 'yellow';
      }
      case CompanyStatus.APPROVED: {
        return 'green';
      }
      case CompanyStatus.DELETED: {
        return 'red';
      }
      default: {
        return 'orange';
      }
    }
  }
}
