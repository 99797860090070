import * as moment from 'moment';
import { User } from './auth.models';
import { Company } from './company.models';
import { Vehicle } from './vehicle.models';
import { Journey } from './journey.models';

export enum TachographStatus {
  AUSENCIA = 'AUSENCIA',
  CONDUCAO = 'CONDUCAO',
  DESCANSO = 'DESCANSO',
  DISPONIBILIDADE = 'DISPONIBILIDADE',
  // DISPONIBILIDADE_2 = 'DISPONIBILIDADE_2',
  OUTRO_EMPREGADOR = 'OUTRO_EMPREGADOR',
  TRABALHO = 'TRABALHO',
  TROCA_VEICULO = 'TROCA_VEICULO',
  INICIO = 'INICIO',
  FIM = 'FIM',
  NR = 'NR',
}

export enum RestType {
  BREAK = 'BREAK',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  VACATION = 'VACATION',
  ABSENCE = 'ABSENCE',
  OTHER = 'OTHER',
}

export class Registration {
  id: string;
  status: TachographStatus | null;
  date: Date | null;
  endDate: Date | null;
  km: number | null;
  endKm: number | null;
  restType: RestType | null;
  notes: string;
  place: string | null;
  longitude: string | null;
  latitude: string | null;
  teamDriving: boolean;
  licensePlate: string | null;
  originalDate: Date | null;

  userId: string | null;
  userCategoryId: string | null;
  companyId: string | null;
  vehicleId: string | null;
  journeyId: string | null;
  originalId: string | null;
  // absenceTypeId: string | null;

  company: Company | null;
  user: User | null;
  userCategory: any | null;
  vehicle: Vehicle | null;
  journey: Journey | null;
  original: any | null;
  // absenceType: Configuration | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.status = $obj && $obj.status ? $obj.status : null;
    this.date = $obj && $obj.date ? new Date($obj.date) : null;
    this.endDate = $obj && $obj.endDate ? new Date($obj.endDate) : null;
    this.km = $obj && $obj.km ? $obj.km : null;
    this.endKm = $obj && $obj.endKm ? $obj.endKm : null;
    this.restType = $obj && $obj.restType ? $obj.restType : null;
    this.notes = $obj && $obj.notes ? $obj.notes : '';
    this.place = $obj && $obj.place ? $obj.place : null;
    this.longitude = $obj && $obj.longitude ? $obj.longitude : null;
    this.latitude = $obj && $obj.latitude ? $obj.latitude : null;
    this.teamDriving = $obj && $obj.teamDriving !== undefined ? $obj.teamDriving : false;
    this.licensePlate = $obj && $obj.licensePlate ? $obj.licensePlate : null;
    this.originalDate = $obj && $obj.originalDate ? new Date($obj.originalDate) : null;

    this.userId = $obj && $obj.userId ? $obj.userId : $obj && $obj.user ? $obj.user.id : null;
    this.userCategoryId =
      $obj && $obj.userCategoryId ? $obj.userCategoryId : $obj && $obj.userCategory ? $obj.userCategory.id : null;
    this.companyId = $obj && $obj.companyId ? $obj.companyId : $obj && $obj.company ? $obj.company.id : null;
    this.vehicleId = $obj && $obj.vehicleId ? $obj.vehicleId : $obj && $obj.companyUser ? $obj.companyUser.id : null;
    this.journeyId = $obj && $obj.journeyId ? $obj.journeyId : $obj && $obj.journey ? $obj.journey.id : null;
    this.originalId = $obj && $obj.originalId ? $obj.originalId : $obj && $obj.original ? $obj.original.id : null;

    this.user = $obj && $obj.user ? new User($obj.user) : null;
    this.userCategory = $obj && $obj.userCategory ? $obj.userCategory : null;
    this.company = $obj && $obj.company ? new Company($obj.company) : null;
    this.vehicle = $obj && $obj.vehicle ? new Vehicle($obj.vehicle) : null;
    this.journey = $obj && $obj.journey ? new Journey($obj.journey) : null;
    this.original = $obj && $obj.original ? $obj.original : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get isDriving() {
    return this.status === TachographStatus.CONDUCAO;
  }
  get isResting() {
    return this.status === TachographStatus.DESCANSO;
  }
  get isAvailable() {
    return this.status === TachographStatus.DISPONIBILIDADE;
  }
  get isJourney() {
    return this.status === TachographStatus.INICIO || this.status === TachographStatus.FIM;
  }

  get statusDescription() {
    let description: string;
    switch (this.status) {
      case TachographStatus.AUSENCIA: {
        description = 'Ausência';
        break;
      }
      case TachographStatus.CONDUCAO: {
        description = 'Condução';
        break;
      }
      case TachographStatus.DESCANSO: {
        description = 'Descanso';
        break;
      }
      case TachographStatus.DISPONIBILIDADE: {
        description = 'Disponibilidade';
        break;
      }
      // case TachographStatus.DISPONIBILIDADE_2: {
      //   description = 'Disponibilidade (Condução em Pares)';
      //   break;
      // }
      case TachographStatus.OUTRO_EMPREGADOR: {
        description = 'Outro Empregador';
        break;
      }
      case TachographStatus.TRABALHO: {
        description = 'Outros Trabalhos';
        break;
      }
      case TachographStatus.TROCA_VEICULO: {
        description = 'Troca de Veículo';
        break;
      }
      case TachographStatus.NR: {
        description = 'Não Registado';
        break;
      }
      case TachographStatus.INICIO: {
        description = 'Início de Serviço';
        break;
      }
      case TachographStatus.FIM: {
        description = 'Fim de Serviço';
        break;
      }
      case TachographStatus.NR:
        description = 'Não Registado';
        break;
      default: {
        description = '';
        break;
      }
    }
    return description;
  }

  get duration() {
    if (this.isJourney) {
      return '';
    }

    if (this.endDate) {
      const diffInMs = moment(this.endDate).diff(moment(this.date), 'milliseconds');
      const duration = moment.duration(diffInMs);
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes()) - hours * 60;
      const seconds = Math.floor(duration.asSeconds()) - hours * 3600 - minutes * 60;

      const minutesStr = minutes.toFixed(0).toString().padStart(2, '0');
      const hoursStr = hours.toFixed(0).toString().padStart(2, '0');
      const secondsStr = seconds.toFixed(0).toString().padStart(2, '0');

      return `${hoursStr}:${minutesStr}:${secondsStr}`;
    } else {
      return 'N/A';
    }
  }

  get scheduller() {
    const endDate = this.endDate ? moment(this.endDate) : moment();
    const isSameDate = moment(this.date).isSame(endDate, 'date');
    return isSameDate
      ? `${moment(this.date).format('DD-MM-YYYY')} ${moment(this.date).format('HH:mm')}-${endDate.format('HH:mm')}`
      : `${moment(this.date).format('DD-MM-YYYY HH:mm')} - ${endDate.format('DD-MM-YYYY HH:mm')}`;
  }

  get image() {
    let image = '';
    switch (this.status) {
      case TachographStatus.AUSENCIA:
        image = 'assets/pictograms/ausencia.svg';
        break;
      case TachographStatus.CONDUCAO:
        image = 'assets/pictograms/conducao.svg';
        break;
      case TachographStatus.DESCANSO:
        image = 'assets/pictograms/descanso.svg';
        break;
      case TachographStatus.DISPONIBILIDADE:
        image = 'assets/pictograms/disponibilidade.svg';
        break;
      // case TachographStatus.DISPONIBILIDADE_2:
      //   image = 'assets/pictograms/disponibilidade_2.svg';
      //   break;
      case TachographStatus.OUTRO_EMPREGADOR:
        image = 'assets/pictograms/outro_empregador.svg';
        break;
      case TachographStatus.TRABALHO:
        image = 'assets/pictograms/trabalho.svg';
        break;
      case TachographStatus.TROCA_VEICULO:
        image = 'assets/pictograms/troca_veiculo.svg';
        break;
      case TachographStatus.INICIO:
        image = 'assets/pictograms/start.svg';
        break;
      case TachographStatus.FIM:
        image = 'assets/pictograms/stop.svg';
        break;
      case TachographStatus.NR:
        image = 'assets/pictograms/nr.svg';
        break;
    }
    return image;
  }

  get distance() {
    return this.status === TachographStatus.CONDUCAO
      ? this.km && this.endKm
        ? Math.round(this.endKm - this.km)
        : 'N/A'
      : '';
  }

  get restTypeDescription() {
    let description: string;
    switch (this.restType) {
      case RestType.BREAK: {
        description = 'Intervalo';
        break;
      }
      case RestType.DAILY: {
        description = 'Diário';
        break;
      }
      case RestType.WEEKLY: {
        description = 'Semanal';
        break;
      }
      case RestType.VACATION: {
        description = 'Férias';
        break;
      }
      case RestType.ABSENCE: {
        description = 'Falta';
        break;
      }
      case RestType.OTHER: {
        description = 'Outro';
        break;
      }
      default: {
        description = 'Outro';
        break;
      }
    }
    return description;
  }
}
