import { Component } from '@angular/core';
import { VersionCheckService } from './shared/services/version-check.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  isCollapsed = false;

  constructor(public versionCheckSrv: VersionCheckService) {
    // CHECK VERSION
    versionCheckSrv.initVersionCheck(environment.version_check_url);
  }
}
