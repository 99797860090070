export enum VehicleType {
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  SEMI = 'SEMI',
}

const vehicleTypeList = [
  { id: VehicleType.TRACTOR, designation: 'tractor' },
  { id: VehicleType.TRAILER, designation: 'Trailer' },
  { id: VehicleType.SEMI, designation: 'Semi' },
];

export function getVehicleTypeList(): any[] {
  return vehicleTypeList;
}
export function getVehicleType(id: VehicleType | null): any {
  return vehicleTypeList.find((item: any) => item.id == id) ?? null;
}
